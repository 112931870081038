*{
  transition: all .1s;
}
html{
  overflow-x: hidden;
}
html, body {

  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #020b0c;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: #fff;
}

footer{
  height: 70px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items:center;
  /* background: #1d1d1d; */
  position: relative;
  bottom: 0;
}
li{
  list-style: none;
  display: inline;
  margin: 0 .5rem;
}
