*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.grid_pelis {
  display: grid;
  min-height: 80vh;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn_container {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  background: #000;
}
.btn_container button {
  padding: 0.5rem 1.5rem;
  background: rgb(39, 200, 39);
  border: none;
  outline: none;
  border-radius: 5px;
  /* margin: 0 1rem; */
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
}
.btn_container p {
  font-size: 1rem;
  text-align: center;
  width: 50px;
}
button:active {
  transform: scale(0.98);
}

/* @media screen and (max-width: 520px) {
  .grid_pelis {
    grid-template-columns: 100%;
    justify-content: center;
  }
} */
