.titulo{
  text-align: center;
}
.main{
  min-height: 100%;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1.5rem;
}

.header .barraBusqueda{
  width: 500px;
  padding: .5rem 1rem;
  font-size:1.2rem;
}

.container .footer{
  width: 100%;
  text-align: center;
}

@media screen and (max-width:420px){
  .header .barraBusqueda {width: 70%;}
  
}