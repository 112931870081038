.contenedor_detalles{
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  gap: 20px;
  font-size: 1.3rem;
  min-height: 100vh;
  background: #020b0c;

}

.contenedor_detalles .titulo{
  font-size:2.5rem;
}

.contenedor_detalles .img {
  width: 300px;
}

.contenedor_detalles .img img{
  max-width: 100%;
}

.contenedor_detalles .textContainer{
  width: 70%;
}

.score{
  width: 50px;
  height: 50px;
  padding: 1.5rem;
  border: 5px solid #0f0;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  outline: 5px solid #fff;
  margin: 1rem 0;


}


@media screen and (max-width:850px){
  .contenedor_detalles{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 10px;
  }

  .contenedor_detalles .titulo{
    font-size:2rem;
  }
}
